/**
 * A class that contains the methods to manipulate the ProgressToasterComponent
 * @author Matheus Ferreira Eziquiel
 *
 * Copyright 2024 Finstein GmbH - All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FireLoggingService } from '../../../../core/services/fire-logging.service';

interface ProgressToasterState {
    isVisible: boolean;
    message: string;
    progress: number;
    icon: string;
    isInfiniteProgress: boolean;
    totalImportLength: number,
    errorMessage: string;
    successMessage: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProgressToasterService {
    private toasterState = new BehaviorSubject<ProgressToasterState>({
        isVisible: false,
        message: '',
        progress: 0,
        icon: '',
        isInfiniteProgress: true,
        totalImportLength: 0,
        errorMessage: '',
        successMessage: ''
    });

    toasterState$ = this.toasterState.asObservable();
    functionSubscription = new Subscription();

    constructor(private fireLogging: FireLoggingService) {}

    /**
     * Make the progress toaster component visible
     * @param message to show in the component wich process is being executed
     * @param icon to show in the component
     * @param totalImportLength to handle the progress
     * @param isInfiniteProgress to enable the infinite progress animation
     */
    showProgressToaster(message: string, icon: string, totalImportLength = 0, isInfiniteProgress = true) {
        this.toasterState.next({
            isVisible: true,
            message,
            progress: 0,
            icon,
            isInfiniteProgress,
            totalImportLength: totalImportLength,
            errorMessage: '',
            successMessage: ''
        });
    }

    /**
     * Updates the progress percentage based on the number of processed items.
     * @param processed to be used to calculate the progress
     */
    updateProgress(processed: number) {
        const currentState = this.toasterState.getValue();
        let progress = 0;

        if (currentState?.totalImportLength) {
            progress = Math.ceil((processed/currentState.totalImportLength) * 100);
        }
        this.toasterState.next({ ...currentState, progress });
    }

    /**
     * Hides the progress toaster.
     */
    hideProgressToaster() {
        this.toasterState.next({ ...this.getToasterValues(), isVisible: false });
    }

    /**
     * Displays an error message in the progress toaster.
     */
    showErrorProgressToaster(errorMessage: string) {
        this.toasterState.next({ ...this.getToasterValues(), progress: 100, errorMessage, isInfiniteProgress: false });
    }

    /**
     * Displays a success message and marks the progress as complete.
     */
    completeProgressToaster(successMessage: string) {
        this.toasterState.next({ ...this.getToasterValues(), progress: 100, successMessage, isInfiniteProgress: false })
    }

    /**
     * Adds a subscription to an observable function to track its progress and logs any errors.
     * @param functionObservable Observable responsible to handle the progress
     * @param functionName to be logged if the functionObservable throws error
     */
    addFunctionObservable(functionObservable: Observable<any>, functionName: string) {
        this.functionSubscription = functionObservable.subscribe({
            next: (processed: number) => {
                if (!this.getToasterValues().isInfiniteProgress) {
                    this.updateProgress(processed);
                }
            },
            error: (error) => {
                const message = error.error
                ? error.error.message
                : error.message;
                this.fireLogging.sendErrorLog(
                    `[listenCompanyPermissions(user.service.ts)] An error occurred while calling the function ${functionName}, details: ${
                        message || JSON.stringify(error)
                    }`
                );
            }
        });
    }

    getToasterValues() {
        return this.toasterState.getValue();
    }
}
